import "./App.css";
import videoBg from "./videoBg.mp4";
import logo from "./logo.png";
import NSF from "./NSF.png";
import NOAA from "./NOAA.png";
import UState from "./UState.png";
import React from "react";
import { Cookies } from "react-cookie";
import { Link } from "react-router-dom";

const cookies = new Cookies();

function App(props) {
  const [value, setValue] = React.useState(0);

  return (
    <div className="App">
      <video src={videoBg} autoPlay loop muted />

      <div className="overlay"></div>
      <div className="content-center">
        <h3 className="main-h3">Machine Learning Based</h3>
        <h1 className="main-h1">Solar Flare Prediction</h1>
        <div className="div-logo">
          <img className="brandlogo" src={UState}></img>
          <img className="brandlogo" src={NSF}></img>
        </div>
      </div>
      <div className="content">
        <button
          className="atag"
          onClick={(event) => {
            if (cookies.get("user-token")) {
              window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
            } else {
              window.location.href = `${process.env.PUBLIC_URL}/login`;
            }
          }}
        >
          Log in
        </button>
        <button
          className="atag"
          onClick={(event) => {
            window.location.href = `${process.env.PUBLIC_URL}/signup`;
          }}
        >
          Sign up
        </button>
        <button
          className="atag"
          onClick={(event) => {
            window.location.href = `${process.env.PUBLIC_URL}/help`;
          }}
        >
          Help
        </button>
        <button
          className="atag"
          onClick={(event) => {
            window.location.href = `${process.env.PUBLIC_URL}/contact`;
          }}
        >
          Contact
        </button>
        <button
          className="atag"
          onClick={(event) => {
            window.location.href = `${process.env.PUBLIC_URL}/about`;
          }}
        >
          About
        </button>
      </div>
    </div>
  );
}

export default App;
