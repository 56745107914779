import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
import logo from "./logo.png"; // Replace with the correct path to your logo
import flareHelp from "./flareHelp.jpg"; // Replace with the correct path to your header image
import { FaEnvelope, FaGithub } from "react-icons/fa";

function Help() {
  return (
    <div className="help-page">
      {/* Header Section */}
      <header className="help-header">
        <Link to="/">
          <img className="help-logo" src={logo} alt="Logo" />
        </Link>
        <h1 className="help-title">Help</h1>
      </header>

      {/* Main Card Section */}
      <div className="help-card">
        <img className="help-card-image" src={flareHelp} alt="Help Header" />
        <div className="help-card-content">
          <section className="help-section">
            <h2 className="help-section-title">Registration</h2>
            <p className="help-text">
              To access and use this application, please email{" "}
              <a
                href="mailto:reza.eskandarinasab@usu.edu"
                className="help-link"
              >
                MohammadReza EskandariNasab
              </a>{" "}
              with your personal information, institutional details, and
              intended use. Once your email is verified in our database, you
              will be able to successfully register via the{" "}
              <Link to="/signup" className="help-link">
                Sign Up
              </Link>{" "}
              page.
            </p>
          </section>

          <section className="help-section">
            <h2 className="help-section-title">Test Datasets</h2>
            <p className="help-text">
              Two sample datasets are available on our{" "}
              <a
                href="https://github.com/samresume/FlaPLeT" // Replace with the correct GitHub link
                target="_blank"
                rel="noopener noreferrer"
                className="help-link"
              >
                GitHub page
              </a>
              . These datasets can be uploaded to your account to explore and
              utilize the application's features.
            </p>
          </section>

          <section className="help-section">
            <h2 className="help-section-title">Uploading Your Datasets</h2>
            <p className="help-text">
              When uploading datasets, ensure that they meet the following
              criteria:
            </p>
            <ul className="help-list">
              <li className="help-list-item">
                The total size should not exceed 25 MB.
              </li>
              <li className="help-list-item">
                Each dataset must include two `.pkl` files:
                <ul>
                  <li>
                    A multivariate time-series dataset in the shape{" "}
                    <code>(num_samples, num_timestamps, num_features)</code>.
                  </li>
                  <li>
                    A binary label file with the shape{" "}
                    <code>(num_samples)</code>.
                  </li>
                </ul>
              </li>
            </ul>
          </section>

          <section className="help-section">
            <h2 className="help-section-title">Usage Limits</h2>
            <p className="help-text">
              Each user is currently allowed up to 5 tasks per day. These tasks
              can include data augmentation, graph generation, or machine
              learning model training. We are constantly working to expand these
              limits as the platform grows.
            </p>
          </section>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="help-footer">
        <p className="help-footer-text">
          &copy; {new Date().getFullYear()} Utah State University. All rights
          reserved.
        </p>
      </footer>
    </div>
  );
}

export default Help;
